import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { MetaData } from "../components/meta"
import Layout from "../components/layout"

const FaleConoscoPage = ({ data }) => {

  const location = {
    pathname: '/fale-conosco/',
    search:'',
    hash:''
  }

  return (
    <Layout>
      <MetaData data={data} location={location} />
      <div className="row">
        <div className="col-md-12">
          <h2>Fale Conosco</h2>
          <hr />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mt-3">
          <h5 className="">Gabinete</h5>
          <div className="col-md-12 map-responsive mt-3">
            <iframe
              title="gabinete"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3839.0811819972796!2d-47.866742684592275!3d-15.799672827295336!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x935a3b39ba8ad207%3A0x6880d024f2b0d40d!2sC%C3%A2mara+dos+Deputados!5e0!3m2!1sen!2sbr!4v1558456717111!5m2!1sen!2sbr"
              width="400"
              height="300"
              frameBorder="0"
              allowFullScreen
            />
          </div>
          <hr />
          <div className="col-md-12">
            <div className="media">
              <Img
                alt="bandeira do brasil"
                className="align-self-center mr-3 border"
                fixed={data.bandeiraBrasilImage.childImageSharp.fixed}
              />

              <div className="media-body">
                <p>
                  Câmara dos Deputados Anexo IV, Gabinete 704 <br /> CEP
                  70160-900 - Brasília/DF
                </p>
                <p className="mb-0">
                  <strong>
                    {" "}
                    Fone: <a href="tel:556132155704">(061) 3215-5704</a>{" "}
                  </strong>
                </p>
              </div>
            </div>
          </div>
          <hr />
        </div>
        <div className="col-md-6 mt-3">
          <h5 className="">Escritório Político</h5>
          <div className="col-md-12 map-responsive mt-3">
            <iframe
              title="escritorio"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3454.1853632499287!2d-51.233407184335384!3d-30.03153953763822!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9519790ffaa61d79%3A0x33b8b09fbec51050!2sR.+Riachuelo%2C+1038+-+1405+-+Centro+Hist%C3%B3rico+de+Porto+Alegre%2C+Porto+Alegre+-+RS%2C+90010-270!5e0!3m2!1sen!2sbr!4v1558456888501!5m2!1sen!2sbr"
              width="400"
              height="300"
              frameBorder="0"
              allowFullScreen
            />
          </div>
          <hr />
          <div className="col-md-12">
            <div className="media">
              <Img
                alt="bandeira rio grande do sul"
                className="align-self-center mr-3 border"
                fixed={data.bandeiraRioGrandeImage.childImageSharp.fixed}
              />
              <div className="media-body">
                <p>
                  Rua Riachuelo, 1038 Sala 1405, Centro Histórico <br /> CEP
                  90010-272 - Porto Alegre/RS
                </p>
                <p className="mb-0">
                  <strong>
                    Fone: <a href="tel:555132251942">(051) 3225-1942</a>
                  </strong>
                </p>
              </div>
            </div>
          </div>
          <hr />
        </div>
        <div className="col-md-12">
          <p>
            <strong>
              E-mail:{" "}
              <a href="mailto::dep.pompeodemattos@camara.leg.br">
                dep.pompeodemattos@camara.leg.br
              </a>
            </strong>
          </p>
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query FaleConoscoImgQuery {
    bandeiraBrasilImage: file(relativePath: { eq: "bandeira_brasil.jpg" }) {
      childImageSharp {
        fixed(width: 150, height: 120, cropFocus: CENTER) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    bandeiraRioGrandeImage: file(
      relativePath: { eq: "bandeira-rio-grande.png" }
    ) {
      childImageSharp {
        fixed(width: 150, height: 120, cropFocus: CENTER) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

export default FaleConoscoPage
